/* eslint-disable prefer-arrow-callback */
<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0 card-bg">
        <b-link class="brand-logo">
          <b-img
            src="@/assets/images/logo/logo2_square.png"
            height="90px"
            width="180px"
            fluid
            alt="Logo"
          />
        </b-link>

        <b-card-title class="mb-1">
          Bienvenido a TicketCrush!
        </b-card-title>
        <b-card-text class="mb-2">
          Porfavor ingresa a tu cuenta para comenzar la experiencia
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login()"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Correo eléctronico"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Contraseña</label>
                <b-link :to="{name:'forgot-password'}">
                  <small>¿Olvidaste tu contraseña?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Iniciar sesión
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>¿Eres nuevo? </span>
          <b-link :to="{name:'auth-register'}">
            <span>Crear cuenta</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            ó
          </div>
        </div>

        <!-- social button -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            variant="facebook"
            @click="loginWithFacebook()"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            variant="google"
            @click="loginWithGoogle()"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BImg, BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import axios from '@axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    // BSV
    BImg,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    const googleScript = document.createElement('script')
    googleScript.setAttribute('src', 'https://apis.google.com/js/client:platform.js?onload=start')
    document.head.appendChild(googleScript)
    const facebookScript = document.createElement('script')
    facebookScript.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js')
    document.head.appendChild(facebookScript)
    window.fbAsyncInit = () => {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: '1841241999491500',
        cookie: true,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v12.0',
      })
    }
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    ...mapActions('notifications', ['connectNotifications']),
    ...mapActions('credits', ['getCredits']),
    afterLogin(id, token) {
      this.connectNotifications(token)
      this.getCredits(id)
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.signIn({ email: this.userEmail, password: this.password })
            .then(response => {
              const userData = response
              useJwt.setToken(response.token)
              useJwt.setRefreshToken(response.refresh_token)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData))
                .then(() => {
                  this.afterLogin(response.id, response.token)
                })
            })
        }
      })
    },
    loginWithFacebook() {
      // eslint-disable-next-line no-undef
      FB.login(response => {
        if (response.authResponse) {
          axios.post('https://135e-2806-108e-14-1457-8d2a-a548-9eb4-c41b.ngrok.io/users/auth/facebook/callback',
            { auth: { access_token: response.authResponse.accessToken, facebook_user_id: response.authResponse.userID } })
            .then(resp => {
              const userData = resp.data
              useJwt.setToken(resp.data.token)
              useJwt.setRefreshToken(resp.data.refresh_token)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData))
                .then(() => {
                  this.afterLogin(response.data.id, response.data.token)
                })
            })
            .catch(error => {
              /* eslint-disable-next-line */
              console.log(error)
            })
        } else {
          /* eslint-disable-next-line */
          console.log('User cancelled login or did not fully authorize.')
        }
      })
    },
    async loginWithGoogle() {
      const googleUser = await this.$gAuth.getAuthCode()
      if (googleUser) {
        axios.get(`users/auth/google_oauth2/callback?code=${googleUser}`, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
          .then(response => {
            const userData = response.data
            useJwt.setToken(response.data.token)
            useJwt.setRefreshToken(response.data.refresh_token)
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$router
              .replace(getHomeRouteForLoggedInUser(userData))
              .then(() => {
                this.afterLogin(response.data.id, response.data.token)
              })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.card-bg{
  background-color: #F1F0FF;
}
</style>
